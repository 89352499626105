import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { fadeAnimation, fadeItem } from '../components/MainStyles/animations'
import { StaticImage } from 'gatsby-plugin-image'
//slider
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper.scss';
import '../components/MainStyles/swiper.scss'
import '../components/MainStyles/navigation.scss'
// import 'swiper/components/navigation/navigation.scss';
// import 'swiper/components/pagination/pagination.scss';
import SwiperCore, { Navigation, Pagination } from 'swiper';

//components
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import {
    StyledPageImageContainer,
    StyledPageDescription,
    StyledPageText,
    StyledPageBackground,


} from '../components/MainStyles/MainContent'

//images
// import AboutImage from '../images/about.jpg'
// import FirstImage from '../images/offimg.jpg'
// import SecondImage from '../images/about.jpg'
// import ThirdImage from '../images/home-background3.jpg'
// import FourthImage from '../images/optima.png'
// import FifthImage from '../images/instagram.svg'

SwiperCore.use([Navigation, Pagination]);
const About = ({ path }) => {

    // const [sliderImages, setSliderImages] = useState([

    //     FirstImage,
    //     SecondImage,
    //     ThirdImage,
    //     FourthImage,
    //     FifthImage,
    // ])

    // const [lastImageNumber, setLastImageNumber] = useState(sliderImages.length - 1)
    // const [lastImage, setLastImage] = useState(sliderImages[sliderImages.length - 1])
    // const [otherImage, setOtherImage] = useState([
    //     lastImage,
    //     sliderImages[lastImageNumber - 2],
    //     sliderImages[lastImageNumber - 3],
    // ])


    // const handleImageChange = () => {
    //     setSliderImages([...setSliderImages,])
    // }

    return (
        <Layout path={path}>
            <SEO
                title="O nas"
                description="Studio art7 sp. z o. o. istnieje od 2003 roku. Obecnie główną działalnością naszej firmy są usługi biura rachunkowego w zakresie prowadzenia księgowości (ryczałt, książka przychodów i rozchodów, księgi handlowe (pełna księgowość), usługi kadrowo - płacowe i podatkowe."
            />
            <StyledAbout>
                <StyledContent>
                    <StyledPageDescription>
                        <StyledPageText variants={fadeAnimation} initial="hidden" animate="show">
                            <motion.h2 variants={fadeItem}>Biuro rachunkowe</motion.h2>
                            <motion.p variants={fadeItem}>Studio art7 sp. z o. o. istnieje od 2003 roku. Obecnie główną
                            działalnością naszej firmy są usługi biura rachunkowego w&nbsp;zakresie
                            prowadzenia księgowości (ryczałt, książka przychodów i&nbsp;rozchodów,
                            księgi
                            handlowe (pełna księgowość), usługi kadrowo -&nbsp;płacowe i&nbsp;podatkowe.
                            Oferujemy rejestrację i&nbsp;kompleksowe prowadzenie firm. Prowadzimy firmy
                            jednoosobowe, spółki oraz organizacje pozarządowe. Jesteśmy
                            autoryzowanym partnerem Comarch. Wykonujemy wdrożenie i&nbsp;szkolenia z&nbsp;ERP
Optima. Zajmujemy się także usługami informatycznymi i&nbsp;szkoleniami BHP.</motion.p>
                        </StyledPageText>
                    </StyledPageDescription>
                    <StyledPageImageContainer>
                        <StyledPageBackground />
                        {/* <StyledPageImage src={AboutImage} alt="" /> */}
                        <StaticImage
                            src='../images/about-img.jpg'
                            alt="Optima image"
                            formats={["AUTO", "WEBP", "AVIF"]}
                            placeholder="blurred"
                            loading="eager"
                            layout="constrained"
                            className="page-img"

                        />
                    </StyledPageImageContainer>
                </StyledContent>
            </StyledAbout>
            {/* <StyledAboutSlider> */}
            {/* {otherImage && otherImage.map(image => (
                    <StyledAboutSliderImage src={image} alt="" />
                ))}
                <button onClick={handleImageChange}>saddsadsa</button>
                <button onClick={() => console.log(otherImage)}>saddsadsa</button> */}
            {/* <StyledAboutSliderImage src={AboutImage} alt="" /> */}
            {/* <StyledAboutSliderImage src={FirstImage} alt="" />
                <StyledAboutSliderImage src={SecondImage} alt="" />
                <StyledAboutSliderImage src={ThirdImage} alt="" />
                <StyledAboutSliderImage src={FourthImage} alt="" />
                <StyledAboutSliderImage src={FifthImage} alt="" /> */}
            <Swiper
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                    1500: {
                        slidesPerView: 3,
                    },
                }}
                slidesPerView={3}
                loop='true'
                grabCursor='true'
                navigation

            >
                <SwiperSlide>
                    {/* <StyledAboutSliderImage src={FirstImage} alt="" /> */}
                    <StaticImage
                        src="../images/slider-img3.webp"
                        alt="slider image"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="blurred"
                        loading="eager"
                        layout="constrained"
                        className="slider-img"

                    />
                </SwiperSlide>
                <SwiperSlide>
                    {/* <StyledAboutSliderImage src={SecondImage} alt="" /> */}
                    <StaticImage
                        src="../images/slider-img2.webp"
                        alt="slider image"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="blurred"
                        loading="eager"
                        layout="constrained"
                        className="slider-img"

                    />
                </SwiperSlide>
                <SwiperSlide>
                    {/* <StyledAboutSliderImage src={ThirdImage} alt="" /> */}
                    <StaticImage
                        src="../images/slider-img.webp"
                        alt="slider image"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="blurred"
                        loading="eager"
                        layout="constrained"
                        className="slider-img"

                    />
                </SwiperSlide>
                {/* <SwiperSlide>

                    <StaticImage
                        src="../images/optima.png"
                        alt="slider image"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="blurred"
                        loading="eager"
                        layout="constrained"
                        className="slider-img"

                    />
                </SwiperSlide> */}
            </Swiper>
            {/* </StyledAboutSlider> */}
        </Layout>
    )
}

const StyledAbout = styled.section`
    display: flex;
    min-height: 91vh;
    justify-content: center;
    align-items: center;
    background: linear-gradient(267.37deg, #ECEBEE 1.05%, rgba(250, 250, 250, 0.06) 122.76%);
`

const StyledContent = styled.div`
    display: flex;
    margin: 10rem 0rem;
    width: 100%;
    ${({ theme }) => theme.media.tablet} {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 10rem 0 5rem 0;
    }
`

// const StyledAboutSlider = styled.div`
//     display: flex;
//     align-items: center;
//     width: 100%;
//     justify-content: center;
//     background: white;
//     padding: 5rem 0;
//     overflow-x: hidden;
// `

// const StyledAboutSliderImage = styled.img`
//     /* margin: 0 4rem; */
//     height: 45rem;
//     width: 80%;
//     object-fit: cover;
//     border-radius: 1.6rem;
//     filter: drop-shadow(0px 4px 74px #D2D0D5);
//     transform: scale(1);
//     transition: transform .2s ease;
//     :hover {
//         transform: scale(1.05);
//     }
// `

export default About
